(function ($) {
  var productCaption = {
    init: function (context) {
      var templates = (typeof context === 'object') && (context.nodeType !== undefined) ? context.querySelectorAll('.js-product-caption--v1') : null;

      if (templates) {
        templates.forEach((template) => {
          var captions = template.dataset.productCaptions;
          var captionsArray = captions.split(',');
          var productBriefs = template.querySelectorAll('.js-product-brief');

          productBriefs.forEach((brief, index) => {
            let captionContainer = brief.querySelector('.js-product__caption');

            captionContainer.innerText = captionsArray[index] || '';
          });
        });
      }
    }
  };

  Drupal.behaviors.productCaptionV1 = {
    attach: function (context) {
      productCaption.init(context);
    }
  };
  $(document).on('productcaption.init', function () {
    productCaption.init(this);
  });
})(jQuery);
